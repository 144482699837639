import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Import Section Title
import SectionTitle from "../common/section-title";
import ClientBox from "./client-box";


//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.png";
import clinet6 from "../../assets/images/clients/6.png";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [
                { name: "Tim Smith", post: "Team Lead", desc: "I was amazed and impressed by how Flexsu helped us to improve the performance, security and user satisfaction on our Gambling platform. They collected, analyzed, and create dashboards that allow me to generate reports and gave me feedback that enabled critical data-driven decisions." },
                { name: "Alex Rosemberg", post: "CTO", desc: "I was clueless and frustrated about the performance and usage problems of our DEFI application. Organizing and collecting data solved this problem and made me happy by providing me with a dashboard that displayed and analyzed all the important metrics of the application and helped me to fix some issues and optimize it." },
                { name: "John Adams", post: "Startup CEO", desc: "I was terrified of losing money and credibility due to reentrancy attacks on our smart contracts. The audits came to the rescue and solved this problem and many others by reviewing and testing our code with their expertise and professionalism." },
                { name: "Ben Foster", post: "Blockchain Devops", desc: "I was anxious and uncertain about the scalability issues of our blockchain project and how it would cope with the growing demand and traffic. Flexsu team contributed to relieving my stress and boosted our confidence by assesing the architecture and helping us to make it more efficient and reliable at scale." },
                { name: "David Williams", post: "Senior Developer", desc: "Launching my smart contract code on the blockchain was a stressful and risky endeavor. Flexsu Audits eased my worries and saved my reputation by conducting a thorough and professional review that fixed several critical issues and improved my code quality" },
                { name: "James Miller", post: "QA Engineer", desc: "I was thrilled and grateful for how Flexsu team tested and measured the scalability of our Defi Exchange and provided us with a report and feedback that helped to fix some key issues and improve the security the application to make it faster and safer on high volume." }
            ],
            clients2: [
                { image: clinet1 },
                { image: clinet3 },
                { image: clinet4 },
                { image: clinet6 },
            ],
            responsive: {
                576: {
                    items: 2
                },
            }
        }
    }

    render() {
        return (
            <React.Fragment>

                <section className="section bg-primary" id="clients">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <div className="text-center mb-0">
                                    {/* subtitle */}
                                    <h5 className="testimonial-subtitle text-uppercase font-22">Testimonial</h5>
                                    {/* title */}
                                    <h4 className="mb-4 font-32 text-white" >What our Clients Say</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <h5 className="mb-4 testimonial-subtitle"><i className="mdi mdi-format-quote-open testimonial-subtitle h1 mr-1"></i> 150 + Satisfied Client</h5>

                                <OwlCarousel
                                    className="owl-theme testi-carousel"
                                    id="testi-carousel"
                                    items={1}
                                    loop={true}
                                    margin={10}
                                    nav={true}
                                    responsive={this.state.responsive}
                                >
                                    <ClientBox clients={this.state.clients} />
                                </OwlCarousel>
                            </Col>
                        </Row>


                        {/* <Row className="mt-5">
                            {
                                this.state.clients2.map((client, key) =>
                                    <Col xl={3} sm={6} key={key} >
                                        <div className="client-images">
                                            <img src={client.image} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row> */}

                    </Container>


                </section>
            </React.Fragment>
        );
    }
}

export default Clients;