import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
function FaqBox(props) {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
            <div>
                <Accordion open={open} toggle={toggle}>
                    {props.qaItems.map((qa, i) => 
                        <AccordionItem key={i}>
                            <AccordionHeader targetId='{i}' >{qa.question}</AccordionHeader>
                            <AccordionBody accordionId='{i}' >{qa.answer}</AccordionBody>
                        </AccordionItem>
                    )}

                </Accordion>
            </div>

    );

}

export default FaqBox;