import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { icon : "search", title : "Smart Contracts Audits", desc : "Careful audit on Smart Contract code looking for reentrancy, gas limit and loops, transaction-ordering dependence, DoS with block gas limit, among others." },
                { icon : "maximize-2", title : "Scalability Benchmarks", desc : "Reviews on projects Architecture to improve bottle necks and increase stability and usability at scale." },
                { icon : "shield", title : "Blockchain Security", desc : "We improve the security of your project be providing: System Penetration Test, Workflow audits, Static and Dynamic analysis on your codebase, ." },
                { icon : "eye", title : "DEFI Monitoring", desc : "Collecting, analyzing, and visualizing data from dApps to gain insights and make data-driven decisions. Understand how the dApp is being used and identify potential issues" },
                // { icon : "layers", title : "Creative Design", desc : "Everyone realizes why a new common would be desirable." },
                // { icon : "code", title : "Quality Code", desc : "To achieve this, it would be necessary to have uniform." },
                // { icon : "tablet", title : "Responsive layout", desc : "Their separate existence is a myth. For science, music, etc." },
            ]
        }
    }
    
    render() {
        return (
            <React.Fragment>
        <section className="section bg-light" id="services">
            <Container>
            <SectionTitle
                subtitle="Our Services"
                title="Take your project to the next level"
                desc="We focus in... "
            />

                <Row>
                    <ServiceBox services={this.state.services} />
                </Row>
                
                 {/* View more button 
                    <Row className="mt-4">
                        <Col lg={12}>
                            <div className="text-center">
                                <Link to="#" className="btn btn-success">View more</Link>
                            </div>
                        </Col>
                    </Row>
                 */}
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Process;