import React from 'react';
import { Row } from "reactstrap";

import FaqBox from "./faq-box";

function Faq(props) {
  const state = {
    qaItems: [
      { question: "How do you perform your security assessments? ", 
        answer: "We use a combination of manual and automated testing methods to perform our security assessments. This includes code review, penetration testing, and vulnerability scanning" },
      { question: "What kind of blockchain platforms do you support", 
        answer: "We support a wide range of blockchain platforms, including Ethereum, EOS, TRON, and more." },
      { question: "How long does it take to complete a security assessment?", 
        answer: "The duration of a security assessment varies depending on the size and complexity of the project. We will provide you with a detailed timeline during the initial consultation." },
      { question: "Do you provide ongoing support after the assessment?", 
        answer: "Yes, we provide ongoing support and maintenance to ensure the continued security of your systems and applications." },
      { question: "How much does your service cost?", 
        answer: "Our pricing varies depending on the specific needs of each project. Contact us for a custom quote and we would be happy to provide you with more information." },
      { question: "How can you help me optimize my smart contracts?", 
        answer: "We can help you optimize your smart contracts by reviewing the code for potential vulnerabilities and inefficiencies, such as gas consumption and reentrancy. We also provide recommendations for improving the performance and security of your smart contracts." },
      { question: "How can dApp monitoring help me improve my dApp?", 
        answer: "By monitoring your dApp, you can gain a better understanding of how it is being used and identify potential issues. This information can be used to make data-driven decisions and improve the performance and user experience of your dApp." }
    ],
  }

  return (
    <Row id="faq" className="faq-row">
      <FaqBox qaItems={state.qaItems} />
    </Row>
  );

}

export default Faq;