import React, { Component } from 'react';
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import Accordion from "../FAQ/faq";

class FaqSection extends Component {
    render() {
        return (<React.Fragment>
            <section className="section bg-primary" id="faq">

                <Container className="faq-row">
                        <Row className="justify-content-center mb-1">
                            <Col lg={7}>
                                <div className="text-center text-white-50">
                                    <h4 className="text-white">Frequently asked questions (FAQ)</h4>
                                    <p className="faq-body">Want to secure and optimize your blockchain projects? You’ve come to the right place. We offer security assessment, smart contract optimization, and dApp monitoring solutions. Check out our FAQs below to learn more about our service.</p>
                                </div>
                            </Col>
                        </Row>
                        <Accordion/>

            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default FaqSection;            